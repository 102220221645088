@import "../../node_modules/bootstrap/less/bootstrap";
@import "_variables";
@import "_mixins";
@import "_gallery";
@import "_opaque";
@import "_icons";
@import "_product";

@navbar-height: 50px;
@transformation-bring-closer: perspective(75em) scale(1.05) rotateX(6deg)
  translateY(-8px);

html {
  scroll-behavior: smooth;
}

body {
  position: relative;
  font-size: 18px;
  padding-top: @navbar-height;
  padding-bottom: 80px;
  background: @bg-inverse;
  overflow-y: scroll;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  text-transform: uppercase;
}

a {
  cursor: pointer;
}

.navbar-brand {
  img {
    height: 20px;
  }
}

/*! purgecss start ignore */
#navigation {
  border: none;
  box-shadow: 0 0 5px @bg-inverse;
  z-index: 9;

  .navbar-collapse {
    font-family: "Montserrat", sans-serif;

    // Overlay above the rest of the content
    // &.in:before {
    //   content: "";
    //   display: block;
    //   width: 100vw;
    //   height: 100vh;
    //   background: rgba(
    //     red(@bg-inverse),
    //     green(@bg-inverse),
    //     blue(@bg-inverse),
    //     0.6
    //   );
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   z-index: -1;
    // }

    ul {
      li {
        &.active {
          a {
            @media (min-width: @grid-float-breakpoint) {
              box-shadow: inset 0 -1px 0 0 darken(#fdedd9, 15%);
            }
          }
        }

        a {
          color: #fdedd9;

          &:hover {
            @media (min-width: @grid-float-breakpoint) {
              box-shadow: inset 0 -1px 0 0 #fdedd9;
            }
          }
        }
      }

      &:first-child {
        li {
          a {
            text-transform: uppercase;
          }
        }
      }
    }
  }
}
/*! purgecss end ignore */

.navbar-flex {
  float: right !important;
  margin-right: 0;
  display: flex;
  flex-direction: row;

  > li {
    border: none !important;
    float: left;
  }
}

section {
  background-color: rgba(27, 27, 19, 0.25);
  color: #fcedd9;
  scroll-margin-top: @navbar-height;

  > header {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    //background-color: rgba(6,6,4,.1);
    //background-blend-mode: multiply;
    height: calc(75vh - @navbar-height);
    max-height: calc(60vw - @navbar-height);
    width: 100%;

    @media (min-width: @screen-lg) {
      background-attachment: fixed;
      background-position: center;
    }

    h2 {
      color: @navbar-inverse-color;
      text-shadow: 0 0 10px @bg-inverse;
      font-size: 48px;
      font-family: "Montserrat", sans-serif;
      font-weight: 500;
      margin: 0;
      z-index: 1;
      opacity: 0.6;

      @media (min-width: @screen-sm) {
        font-size: 90px;
      }

      @media (min-width: @screen-md) {
        font-size: 120px;
      }

      @media (min-width: @screen-lg) {
        font-size: 150px;
      }

      @media (min-width: @screen-xl-min) {
        font-size: 200px;
      }
    }
  }

  > div {
    padding-top: 5vh;
    padding-bottom: 5vh;

    a:not(.btn) {
      color: #995f5f;
    }
  }
}

*[data-popup] {
  cursor: pointer;
}

/*! purgecss start ignore */
.fancybox-content {
  color: #060604;
  background-color: #f6f0e9;
}

/*! purgecss end ignore */

@media (min-width: @screen-xl-min) {
  .col-xl-3 {
    width: 16.66666667%;
  }
}

.lead {
  font-weight: 700;
}

.btn {
  text-transform: uppercase;
  padding-right: 40px;
  padding-left: 40px;
  font-family: "Montserrat", sans-serif;
}
.bg-light {
  background-color: @bg-light;
  color: @bg-inverse;
  .thumbnail .caption {
    color: @bg-inverse;
  }
}
