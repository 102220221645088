.gallery {
  margin: -5px;

  a {
    display: block;
    float: left;
    margin: 5px;
    width: ~"calc(50% - 10px)";

    @media (min-width: @screen-xs-min) {
      width: ~"calc(33% - 10px)";
    }

    @media (min-width: @screen-md-min) {
      width: ~"calc(25% - 10px)";
    }

    @media (min-width: @screen-lg-min) {
      width: ~"calc(20% - 10px)";
    }

    img {
      width: 100%;
      height: auto;
      transition: transform 0.2s;
      transform-origin: 75% center;
      box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.7);
    }

    &:hover {
      img {
        transform: @transformation-bring-closer;
      }
    }

    &:nth-child(9) {
      @media (min-width: @screen-xs-min) and (max-width: @screen-sm-max) {
        display: none;
      }
      @media (min-width: @screen-lg-min) {
        display: none;
      }
    }
  }
}