@vertical-spacing: 80px;

.products {
    margin-bottom: -@vertical-spacing;
    
    .row.d-flex {
        display: flex;
        flex-wrap: wrap;
        
        > [class*='col-'] {
            display: flex;
            flex-direction: column;
        }
    }

    .thumbnail {
        margin-bottom: @vertical-spacing;
        height: 100%;

        .row {
            height: 100%;
            display: flex;
            flex-direction: row;

            > [class*='col-'] {
                &.img-wrapper + * {
                    flex: 1;
                }
            }
            @media (min-width: @screen-lg-min) {
                flex-direction: column;
            }
        }
        .img-wrapper {
            padding-top: 9px;

            @media (min-width: @screen-lg-min) {
                padding-top: 0;
            }
            
            img {
                transition: transform 0.3s;
                transform-origin: 75% center;
                aspect-ratio: 332/600;
                width: auto;
                height: auto;
                max-width: 100%;
                max-height: 50vh;
                object-fit: contain;
            }
        }

        h2 {
            font-size: 28px;

            @media (max-width: @screen-md-max) {
                margin-top: 0;
            }
        }

       
        &:hover {
            text-decoration: none;

            img {
                transform: @transformation-bring-closer;
            }
        }

        .dl-horizontal {
            @media (min-width: @screen-lg-min) {
                dt {
                    width: 100px;
                }

                dd {
                    margin-left: 120px;
                }
            }
        }

        .caption {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
    }
}
