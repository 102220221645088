.fa {
  display: inline-block;
  width: 1em;
  height: 1em;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  vertical-align: middle;

  &.fa-facebook-square {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAAclBMVEUAAAD97dn97dn97dn97dn97dn97dn97dn97dn97dn97dn97dn97dn97dn97dn97dn97dn97dn97dn97dn97dn97dn97dn97dn97dn97dn97dn97dn97dn97dn97dn97dn97dn97dn97dn97dn97dn97dlMy8ebAAAAJXRSTlMAAQIEBgsNDg8YGxweIyQnUV1hYmZoaX6dr9rc3uDk7fHz9ff9vrtT2wAAAGdJREFUGFd9zE0DQlAQRuFzL3WRUuQjJMX8/79odTUsnNU7z2IAjA8AU37EN78cPEXXWwa/KxeEIhf8XQOIZCuk2HuhIeIqosGRaXAAhHuI//Adz9zG6eCHgvcWEvINtBbz6Fb4NScWI7cZsQde72gAAAAASUVORK5CYII=");
  }

  &.fa-envelope-square {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAAgVBMVEUAAAD97dn97dn97dn97dn97dn97dn97dn97dn97dn97dn97dn97dn97dn97dn97dn97dn97dn97dn97dn97dn97dn97dn97dn97dn97dn97dn97dn97dn97dn97dn97dn97dn97dn97dn97dn97dn97dn97dn97dn97dn97dn97dkWEdaPAAAAKnRSTlMAAQMECAkQFhcYISwxPkBOUVZXWVxebHBzeXuGiImwsr7HzNna4Oj1+ftxKYOrAAAAa0lEQVQYV2XORwKCUBAE0dJvjigq5oAS7Psf0MUwKFK7frMZWD/lldchbPRb0SNrgOZISu9VuRQhHfGCwSM4zAz2ydTuuwpWTC4DWCQBBzrL82kMX/AMDvXuGvz90fp029hFH+JXPd+3Ea0+/gEYuUKeeuYAAAAASUVORK5CYII=");
  }

  &.fa-phone-square {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAAPFBMVEXDt6aMhHZiXFHTxrRUUEWpn49vaV397dnEuKcbGxPv4M0pKB+akYJ+d2mLg3Wono7g0r/h08Du38zSxbNSiFgEAAAAYklEQVQY013PyxaAIAgEUDSQRnv3//+aVpo0K7iL4UBMJkyjmpwdiCvzBzEBrocJP/AZpIcjAHsPGsCmVIB6JU4+qiTM+kAeEXKB1xcc7sxaYSwrkzbQZVm3duv/3EA8mJ0vgbkG+UsBi1MAAAAASUVORK5CYII=");
  }

  &.fa-phone {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAAWlBMVEUAAAAzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMHgWvyAAAAHXRSTlMAAQUJExgsLTQ4RFFUaYaUoqO0urzF2uLo6+/z+7DbDaIAAABpSURBVBgZTcEHFoIwFEXBFxJ6U5Qqd//bNIAe/ozkm1pWskIqowUGGRNRqdtGVOjWw57LyOApy80QZFUwOhnuDZ0sv0DnJBWlLuEDY1AOQ6pTWIDHTlTr5F/8NLq4auawev25rN+mNtEXxVcIaYdS8aAAAAAASUVORK5CYII=");
  }

  &.fa-envelope {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAAdVBMVEUAAAAzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzMzPjs4efAAAAJnRSTlMAAQIEBQ4PEBgcHSUmS01PUV1eZHV+f4OGm6aytMHFzs/p6/f7/czO+8YAAABoSURBVBgZhcFJEoIwAEXBH6OCE6AojoACefc/oqmUi0QXdutHNRIZSw0kXjqROGp2I3I1Ks1u4mMqTCEey6wj6LLFHYE72BqvtnsHwnvm67Zd5T2eCM6b7YVAfJEj4dSQaCQ7j1j99wbHWRkFec3JOQAAAABJRU5ErkJggg==");
  }

  &.fa-shopping-cart {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAAWlBMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////////////////////////////////9ZMre9AAAAHXRSTlMAAQIDBAULEygvMDFDRUpRZmuDj5i3wMzk6O/z9Z83+3kAAABYSURBVBhXfY5HDoAwDMDcsvcqm/z/mxxaAe0BX6w4UhQIqUS27qEB0PIhAVjCMIShCEP6CREAh8i5TH2dZzEAmNYuHszo3ijtrN4TLlyrZ9Q+e4ZE+/7nBm4fCyS4XGGoAAAAAElFTkSuQmCC");
  }
}